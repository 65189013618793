import React, { useEffect, useState } from "react";
import {
    GoogleLogin,
    // googleLogout,
    GoogleOAuthProvider,
} from "@react-oauth/google";
import { useKardzAppDispatch, useKardzAppSelector } from "../../store/store";
import { jwtDecode } from "jwt-decode";
import { toggleLogin, userInfo } from "../../store/actions";
import axios from "axios";
import { kardz_url_login } from "../../variables";

// const { REACT_APP_CLIENT_ID } = process.env;
const CLIENT_ID =
    "374169744841-5bhijqlfbjmca09soll74o4k3j3d7jvb.apps.googleusercontent.com";

type Props = {};

function Login({}: Props) {
    const _user = useKardzAppSelector((state) => state.user);
    const dummy_user = {
        user_id: "guest",
        img_url: "guest",
        user_name: "Guest",
        user_email: "email",
        auth_token: "auth",
    };
    const loggedIn = useKardzAppSelector((state) => state.login);
    const [user, setUser] = useState(_user || dummy_user);

    const dispatch = useKardzAppDispatch();


    const Login = async ({ sub, name, email, picture }: any) => {
        try {
            const res = await axios.post(kardz_url_login, {
                google_id: sub,
                name,
                email,
                img_url: picture,
            });
            return res;
        } catch (error) {
            console.log("index login", error);
        }
        // console.log(" Data >> Login >> ", res.data.login);
    };

    const log_in = (userObject: any) => {
        if (!userObject) return;
        const { name, sub, picture, email } = userObject as any;

        // login to admin io with google credits
        Login({ name, sub, picture, email }).then(async (data) => {
            dispatch(toggleLogin(true)); // this.setState({ loggedIn: true });
            // props.data.loggedIn(true);
            var payload = {
                user_id: sub,
                img_url: picture,
                user_name: name,
                user_email: email,
                auth_token: data?.data["auth-token"],
            };
            setUser(payload);
            dispatch(userInfo(payload));
        });
    };

    const onSignIn = (googleUser: any) => {
        const userObject = jwtDecode(googleUser.credential);
        localStorage.setItem("user", JSON.stringify(userObject));
        log_in(userObject);
    };

    // const signOut = () => {
    //     localStorage.setItem("user", "null");
    //     dispatch(toggleLogin(false));
    //     googleLogout();
    // };

    useEffect(() => {
        if (_user) setUser(_user);
        else setUser(dummy_user);
    }, [_user]);

    useEffect(() => {
        const storedValue = localStorage.getItem("user");
        if (storedValue !== null) {
            log_in(JSON.parse(storedValue));
        }
        return () => {};
    }, []);

    return (
        <div className="flex w-full flex-row-reverse px-3 max-h-10">
            {loggedIn ? (
                <>
                    <div className="flex gap-2 items-center font-serif">
                        <div>{user.user_name}</div>
                        <img
                            className="rounded-full h-10 w-10"
                            src={user.img_url}
                            alt={user.user_name}
                        />
                    </div>
                </>
            ) : (
                <GoogleOAuthProvider clientId={CLIENT_ID!}>
                    <GoogleLogin
                        // type="icon"
                        useOneTap
                        auto_select
                        onSuccess={(credentialResponse) => {
                            onSignIn(credentialResponse);
                        }}
                        onError={() => {
                            console.log("Login Failed");
                        }}
                    />
                </GoogleOAuthProvider>
            )}
        </div>
    );
}

export default Login;
