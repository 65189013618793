import * as React from "react";
import {
    Box,
    Button,
    TextField,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemText,
    OutlinedInput,
    CircularProgress,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { categories, speakText, wordSchema } from "./utils/util";

import {
    kardz_url_list,
    kardz_url_new,
    kardz_url_update,
} from "./../../variables";

import { useKardzAppDispatch, useKardzAppSelector } from "../../store/store";
import { korean_update_words_list } from "../../store/actions/actionTypes";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RenderIfVisible from "react-render-if-visible";

type Props = {};

export const KoreanEditor = (props: Props) => {
    const { all_words } = useKardzAppSelector((state) => state.korean);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [editing, setEditing] = React.useState<number>(-1);
    const dispatch = useKardzAppDispatch();
    const dispatch_action = React.useCallback(
        (action: any) => dispatch(action),
        []
    );

    const fetch_words = async () => {
        setLoading(true);
        try {
            const { data } = await axios.post(kardz_url_list, {});

            if (data.length) {
                // get_progres_count(data);
                dispatch_action({
                    type: korean_update_words_list,
                    payload: [...data],
                });
            }
        } catch (error) {
            console.error("Error fetching document:", error);
            throw error; // Rethrow the error for further handling
        }
        setLoading(false);
    };

    const editword_cb = () => {
        fetch_words();
        setEditing(-1);
    };

    return (
        <div className="w-[70%] p-10 flex flex-col gap-2 h-full justify-center">
            <Button
                className="w-fit"
                variant="contained"
                color="secondary"
                onClick={() => fetch_words()}
                // disabled={!states.next}
            >
                {loading ? <CircularProgress size={20} /> : "Get Words"}
            </Button>
            <SearchBar items={all_words} />
            <div className="flex gap-1 flex-col overflow-y-scroll h-[70%] border-2 rounded-md p-2">
                {all_words.map((word: wordSchema, i: number) => (
                    <RenderIfVisible>
                        <div className="flex gap-1 justify-between bg-slate-200">
                            {editing === i ? (
                                <>
                                    <EditWord
                                        _word={word}
                                        onSubmit={editword_cb}
                                    />
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={() => setEditing(-1)}
                                    >
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button onClick={() => setEditing(i)}>
                                        Edit
                                    </Button>
                                    <WordView word={word} i={i} />
                                </>
                            )}
                        </div>
                    </RenderIfVisible>
                ))}
            </div>
            <NewWord />
        </div>
    );
};

export function EditWord({
    _word,
    onSubmit,
    only_category = false,
}: {
    _word: wordSchema;
    onSubmit: () => void;
    only_category?: boolean;
}) {
    // const _word: wordSchema = {
    //     value: "None",
    //     alpha: "None",
    //     category: [],
    //     meta: { correct: 0, wrong: 0 },
    // };
    const [word, setWord] = React.useState(_word);
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setWord((prevWord) => ({
            ...prevWord,
            [name]: value,
        }));
    };

    const handleCategoryChange = (event: SelectChangeEvent<string[]>) => {
        const { value } = event.target;
        // console.log(value);
        setWord((prevWord) => ({
            ...prevWord,
            category: value as string[],
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await axios.post(kardz_url_update, {
                ...word,
            });
            setLoading(false);
            toast.info("Word Updated!");
            setWord(_word);
            onSubmit();
            // dispatch_action({ type: korean_update_words_list, payload: data });
        } catch (error) {
            console.error("Error fetching document:", error);
            throw error; // Rethrow the error for further handling
        }
        setLoading(false);
    };

    React.useEffect(() => {
        setWord(_word);
        return () => {
            // setWord(_word);
        };
    }, [_word]);

    return (
        <Box className="flex gap-1 items-center border-0 p-2 py-0 rounded-md">
            {!only_category && (
                <>
                    <TextField
                        size="small"
                        label="Value"
                        name="value"
                        value={word.value}
                        onChange={handleChange}
                        className="w-48"
                    />
                    <TextField
                        size="small"
                        label="Alpha"
                        name="alpha"
                        value={word.alpha}
                        onChange={handleChange}
                        className="w-96"
                    />
                </>
            )}
            <FormControl size="small" sx={{ m: 0, width: 400 }}>
                <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                <Select
                    size="small"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={word.category}
                    onChange={handleCategoryChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 25 * 4.5 + 2,
                                width: 200,
                            },
                        },
                    }}
                >
                    {categories.map((category) => (
                        <MenuItem
                            sx={{
                                height: "30px",
                                paddingLeft: "5px",
                            }}
                            key={category}
                            value={category}
                        >
                            <Checkbox
                                checked={word.category.includes(category)}
                            />
                            <ListItemText primary={category} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
             
            <Button
                // sx={{padding : 0}}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                {loading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
            </Button>
        </Box>
    );
}

export function WordView({ word, i = 0 }: { word: wordSchema; i: number }) {
    return (
        <div
            key={word.alpha + i}
            className=" flex gap-2 px-1 w-full rounded-sm"
            style={{
                background: `linear-gradient(to right, #dff7d7, #dff7d7  ${
                    word
                        ? (100 * word?.meta?.correct!) /
                          (word?.meta?.correct! + word?.meta?.wrong!)
                        : ""
                }%, #f7d7d7 ${
                    word
                        ? (100 * word?.meta?.correct!) /
                          (word?.meta?.correct! + word?.meta?.wrong!)
                        : ""
                }%, #f7d7d7)`,
            }}
        >
            <div className="m-1 px-2 bg-pink-100 rounded-md">{i}</div>
            <div
                className="m-1 px-2 bg-white rounded-md w-36 cursor-pointer"
                onClick={() => speakText(word.alpha, "Kr")}
            >
                {word.alpha}
            </div>
            <div className="m-1 px-2 bg-white rounded-md">{word.value}</div>
            {word.category.length ? (
                word.category.map((cat) => (
                    <div className="m-1 px-2 bg-slate-300 rounded-md">
                        {cat}
                    </div>
                ))
            ) : (
                <></>
            )}

            <div className=" flex gap-1 rounded-md p-0 justify-center items-center ">
                <div className=" bg-gray-300 rounded-md px-1">
                    {word.meta?.correct}/{word.meta?.wrong}
                </div>
            </div>
        </div>
    );
}

export function NewWord() {
    const _word: wordSchema = {
        value: "None",
        alpha: "None",
        category: [],
        meta: { correct: 0, wrong: 0 },
    };
    const [word, setWord] = React.useState(_word);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setWord((prevWord) => ({
            ...prevWord,
            [name]: value,
        }));
    };

    const handleCategoryChange = (event: SelectChangeEvent<string[]>) => {
        const { value } = event.target;
        // console.log(value);
        setWord((prevWord) => ({
            ...prevWord,
            category: value as string[],
        }));
    };

    const handleSubmit = async () => {
        try {
            await axios.post(kardz_url_new, {
                ...word,
            });
            toast.info("New word Added!");
            setWord(_word);
            // dispatch_action({ type: korean_update_words_list, payload: data });
        } catch (error) {
            console.error("Error fetching document:", error);
            throw error; // Rethrow the error for further handling
        }
    };

    return (
        <Box className="flex gap-2 items-center border-2 p-2 rounded-md">
            <TextField
                size="small"
                label="Value"
                name="value"
                value={word.value}
                onChange={handleChange}
                className="w-48"
            />
            <TextField
                size="small"
                label="Alpha"
                name="alpha"
                value={word.alpha}
                onChange={handleChange}
                className="w-96"
            />
            <FormControl size="small" sx={{ m: 0, width: 600 }}>
                <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                <Select
                    size="small"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={word.category}
                    onChange={handleCategoryChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 25 * 4.5 + 2,
                                width: 200,
                            },
                        },
                    }}
                >
                    {categories.map((category) => (
                        <MenuItem
                            sx={{
                                height: "30px",
                                paddingLeft: "5px",
                            }}
                            key={category}
                            value={category}
                        >
                            <Checkbox
                                checked={word.category.includes(category)}
                            />
                            <ListItemText primary={category} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
             
            <Button
                // sx={{padding : 0}}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                New
            </Button>
        </Box>
    );
}

function SearchBar({ items }: { items: wordSchema[] }) {
    const [searchTerm, setSearchTerm] = React.useState("");
    const [filteredItems, setFilteredItems] = React.useState<
        { word: wordSchema; index: number }[]
    >([]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;
        setSearchTerm(searchValue);
        if (searchValue.trim() === "" || searchValue.trim().length === 0) {
            setFilteredItems([]);
            return;
        }

        let filtered: { word: wordSchema; index: number }[] = [];

        items.map((item, i) => {
            if (
                item.alpha.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.value.toLowerCase().includes(searchValue.toLowerCase())
            ) {
                filtered.push({ word: item, index: i });
            }
            return;
        });
        setFilteredItems(filtered);
    };

    return (
        <div className="relative w-full z-50 ">
            <TextField
                size="small"
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
            />

            {filteredItems.length ? (
                <>
                    <div className="absolute max-h-96 overflow-y-scroll bg-slate-400 rounded-md p-2 w-full border-2 border-black flex flex-col gap-1">
                        {/* <List> */}
                        {filteredItems.map(({ word: item, index: i }) => (
                            <RenderIfVisible>
                                <div
                                    className="flex gap-1"
                                    key={item.alpha + i}
                                >
                                    <WordView word={item} i={i} />
                                    <div className="w-72">
                                        <EditWord
                                            _word={item}
                                            onSubmit={() => null}
                                            only_category
                                        />
                                    </div>
                                </div>
                            </RenderIfVisible>
                        ))}
                        {/* </List> */}
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
}
